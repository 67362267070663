// These are copied from Bootstrap so that we can use and redefine default values for the theme.

// Color system
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// Plone specific colors
//colors
$state-draft-color:                 #fab82a!default; // lime-yellow //draft is visible
$state-pending-color:               #ccd111!default; // orange
$state-private-color:               #c4183c!default; // red
$state-internal-color:              #fab82a!default; // is draft
$state-internally-published-color:  #883dfa!default; // is intranet

// Plone
// Bootstrap $primary color is Plone blue
$primary:                           #007bb1!default; //plone blue made slightly darker for wcag 2.0

$plone-link-color:                  $primary !default;
$plone-link-color-on-dark:          #16a1e3!default; //plone blue
//if you need different contrast
$plone-link-color-on-grey:          #086ca3!default; //valid wcag 2.0
$plone-link-hover-color:            darken($plone-link-color, 15%)!default;

$plone-light-color: $gray-100 !default;
$plone-dark-color: $gray-900 !default;

// Portlets
$plone-portlet-list-hover-bg:       #fcfcfd!default;
$plone-portlet-footer-bg:           #fcfcfd!default;
$plone-portlet-list-bullet:         #64bee8!default;

$plone-colors: (
  "link-color":                     $plone-link-color,
  "link-color-on-dark":             $plone-link-color-on-dark,
  "link-color-on-grey":             $plone-link-color-on-grey,
  "link-hover-color":               $plone-link-hover-color,
  "portlet-list-hover-bg":          $plone-portlet-list-hover-bg,
  "portlet-footer-bg":              $plone-portlet-footer-bg,
  "portlet-list-bullet":            $plone-portlet-list-bullet,
) !default;

$state-colors: (
  "draft":                          $state-draft-color,
  "pending":                        $state-pending-color,
  "private":                        $state-private-color,
  "internal":                       $state-internal-color,
  "internally-published":           $state-internally-published-color,
) !default;
